import React from 'react';
import { useTheme } from '@mui/material/styles';

interface IllustrationProps {
  width?: number | string;
  height?: number | string;
  fillColor?: string;
}

const DaiIcon = ({
  width = 23,
  height = 23,
}: IllustrationProps): JSX.Element => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 500 500">
      <rect x="84" y="86" fill={theme.palette.common.white} width="336" height="324" />
      <g>
        <path fill={theme.palette.secondary.main} d="M182.3,342.1h75c35.4,0,66-20.4,80.7-50H182.3V342.1z" />
        <path fill={theme.palette.secondary.main} d="M257.3,157.9h-75v51.3h156.3C324.1,178.9,293.2,157.9,257.3,157.9z" />
        <path fill={theme.palette.secondary.main} d="M250,0C111.9,0,0,111.9,0,250s111.9,250,250,250s250-111.9,250-250S388.1,0,250,0z M398.8,236.2h-23.3
     c0.5,4.5,0.8,9.1,0.8,13.8c0,5.1-0.4,10.2-1,15.1h23.4v27h-30.2c-16.9,44.3-59.8,75.8-110.1,75.8H155.3v-75.8h-32.8v-27h32.8v-28.9
     h-32.8v-27h32.8v-77.1h103.1c50.8,0,94,32.1,110.6,77.1h29.7h0.1V236.2z"/>
        <path fill={theme.palette.secondary.main} d="M182.3,236.2v28.9h164.2c0.6-4.2,0.9-8.6,0.9-13V248c0-4-0.3-7.9-0.8-11.8H182.3z" />
      </g>
    </svg>
  );
};

export default DaiIcon;
import React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { MetaMaskIcon } from 'assets';

interface Props {
    open: boolean,
    onClose: () => void;
}

export default function InstallWalletDialog({ open, onClose }: Props) {
    return (

        <Dialog
            onClose={onClose}
            open={open}
            maxWidth={'sm'}
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: 4,
                },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingY: { xs: 4, sm: 10 },
                    paddingX: { xs: 4, sm: 12 },
                }}
            >
                <Typography
                    variant={'h5'}
                    fontWeight={700}
                    align={'center'}
                    marginBottom={4}
                >
                    Install Wallet
                </Typography>
                <Typography
                    sx={{ marginBottom: 5 }}
                    align={'center'}>To start using Athame Finance</Typography>

                <Tooltip title='Metamask'>
                    <IconButton component={Link} href='https://metamask.io/download/' target='_blank'>
                        <MetaMaskIcon />
                    </IconButton>
                </Tooltip>
                <Typography
                    align={'center'}
                    sx={{ textDecoration: 'underline', cursor: 'pointer', marginTop: 5 }}
                    onClick={onClose}
                >
                    Close
                </Typography>
            </Box>
        </Dialog>

    );
}
import React from 'react';
import Box from '@mui/material/Box';
import { useTheme, alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import { SvgIconProps, SvgIconTypeMap } from '@mui/material';

interface Props {
    label: string;
    value?: string;
    icon: SvgIconTypeMap | SvgIconProps;
}

const DashboardItem = ({ label, value, icon }: Props): JSX.Element => {
    const theme = useTheme();

    return (
        <Grid
            item
            xs={12}
            md={3}
            sx={{
                borderRight: {
                    xs: 0,
                    md: `1px solid ${theme.palette.divider}`,
                },
                borderBottom: {
                    xs: `1px solid ${theme.palette.divider}`,
                    md: 0,
                },
                '&:last-child': {
                    borderRight: 0,
                    borderBottom: 0,
                },
            }}
        >
            <Box
                sx={{
                    p: { xs: 2, md: 4 },
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                }}
            >
                <Box>
                    <Typography gutterBottom>{label}</Typography>
                    {
                        value ? (
                            <Typography
                                variant={'h4'}
                                color={'primary'}
                                fontWeight={700}
                                display={'flex'}
                                alignItems={'flex-end'}
                                lineHeight={1}
                            >
                                {value}
                            </Typography>
                        ) : (
                            <Skeleton variant="text" />
                        )
                    }
                </Box>
                <Typography
                    component={'span'}
                    variant={'caption'}
                    fontWeight={700}
                    sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        marginLeft: 1,
                        bgcolor: alpha(theme.palette.success.light, 0.1),
                        color: theme.palette.secondary.main,
                        paddingX: 1,
                        paddingY: 0.5,
                        borderRadius: 4,
                    }}
                >
                    <Box
                        component={'svg'}
                        xmlns="http://www.w3.org/2000/svg"
                        width={14}
                        height={14}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        marginRight={0.5}
                    >
                        {icon}
                    </Box>
                </Typography>
            </Box>
        </Grid>
    );
};

export default DashboardItem;
import { DashboardAction } from './enums';
import { TState, TAction } from './types';

export default function InvestReducer(state: TState, action: TAction): TState {
    switch (action.type) {
        case DashboardAction.SetTotalAccounts: {
            return {
                ...state,
                totalAccounts: action.payload,
            };
        }
        case DashboardAction.SetTotalShareCount: {
            return {
                ...state,
                totalShareCount: action.payload,
            };
        }
        case DashboardAction.SetSharePrice: {
            return {
                ...state,
                sharePrice: action.payload,
            };
        }
        case DashboardAction.SetTotalClaimed: {
            return {
                ...state,
                totalClaimed: action.payload,
            };
        }
        case DashboardAction.SetTotalUnclaimed: {
            return {
                ...state,
                totalUnclaimed: action.payload,
            };
        }
        case DashboardAction.SetTotalReserves: {
            return {
                ...state,
                totalReserves: action.payload,
            };
        }
        case DashboardAction.SetEvent: {
            return {
                ...state,
                events: [...state.events.filter(ev => ev.transactionHash !== action.payload.transactionHash), action.payload]
            };
        }
        default:
            return state;
    }
}
import React, { useContext, useEffect, useCallback, useReducer } from 'react';
import { ethers, BigNumber } from 'ethers';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { UsdIcon, WavyBackground } from 'assets';
import InputAdornment from '@mui/material/InputAdornment';
import Skeleton from '@mui/material/Skeleton';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import { AthameContext } from 'context';
import appReduce from './reducer';
import { initialState } from './reducer';
import { InvestAction, InState } from './enums';
import { toFloat } from 'helpers';
import { getToken, ATHAME_FINANCE_TOKEN } from '../../../../../constants';

const ContractAddress = process.env.REACT_APP_DEPOSITORY_ADDRESS;
const token = getToken();
const APPROVE_TEXT = 'Approve';
const BUY_TEXT = 'Buy';

const Invest = (): JSX.Element => {
  const theme = useTheme();
  const {
    metaMaskEnabled,
    correctNetwork,
    providerDepository,
    providerDepositToken,
    signerDepositToken,
    signerDepository,
    currentWallet,
    loading,
    toggleLoading
  } = useContext(AthameContext);

  const [state, dispatch] = useReducer(appReduce, initialState);
  const msg = state.inState === InState.NeedAllowance ? APPROVE_TEXT : BUY_TEXT;

  const resetState = useCallback(async () => {
    dispatch({ type: InvestAction.Reset });
  }, [metaMaskEnabled]);

  const getPaused = useCallback(async () => {
    if (!providerDepository) return;

    const paused = await providerDepository.paused();
    dispatch({ type: InvestAction.SetPaused, payload: paused });

  }, [providerDepository]);

  const getSharePrice = useCallback(async () => {
    if (!providerDepository) return;

    const price = toFloat(await providerDepository.sharePrice(), token.decimals);

    dispatch({ type: InvestAction.SetSharePrice, payload: price });

  }, [providerDepository]);

  const getBalance = useCallback(async () => {
    if (!(providerDepositToken && currentWallet)) return;

    const balance = await providerDepositToken.balanceOf(currentWallet.address);
    dispatch({ type: InvestAction.SetBalance, payload: toFloat(balance, token.decimals) });

  }, [providerDepositToken, currentWallet]);

  const getDividends = useCallback(async () => {
    if (!(providerDepository && currentWallet)) return;

    const [, , unclaimedDividends] = await providerDepository.investors(currentWallet.address);
    dispatch({ type: InvestAction.SetDividends, payload: toFloat(unclaimedDividends, token.decimals) });

  }, [providerDepository, currentWallet]);

  const getAllowance = useCallback(async () => {
    if (!(providerDepositToken && currentWallet)) return;

    const allowance = await providerDepositToken.allowance(currentWallet.address, ContractAddress);
    const approvalAmount = ethers.utils.parseUnits((state.shares * state.sharePrice).toString(), token.decimals);

    if (allowance.gte(approvalAmount)) {
      dispatch({ type: InvestAction.SetInState, payload: InState.HasAllowance });
    } else {
      dispatch({ type: InvestAction.SetInState, payload: InState.NeedAllowance });
    }

  }, [state.shares, providerDepositToken, currentWallet]);

  async function setAllowance(approvalAmount: BigNumber) {

    toggleLoading();

    try {
      const tx = await signerDepositToken.approve(ContractAddress, approvalAmount);
      await tx.wait();
      dispatch({ type: InvestAction.SetInState, payload: InState.HasAllowance });
    } catch (ex) {
      console.log(ex);
    }

    toggleLoading();
  }

  async function buyShares(count: number) {
    if (!(providerDepositToken && currentWallet)) return;

    if (state.inState !== InState.HasAllowance) {
      const approvalAmount = ethers.utils.parseUnits((count * state.sharePrice).toString(), token.decimals);
      await setAllowance(approvalAmount);
    }
    else {
      try {
        toggleLoading();
        const tx = await signerDepository.buyShares(count);
        await tx.wait();
        await getAllowance();
        await getBalance();
      } catch (ex) {
        console.log(ex);
      }

      toggleLoading();
    }

  }

  async function claim() {
    if (!(signerDepository && currentWallet)) return;

    toggleLoading();
    try {
      const tx = await signerDepository.claim();
      await tx.wait();
      dispatch({ type: InvestAction.SetDividends, payload: null });
    } catch (ex) {
      console.log(ex);
    }

    toggleLoading();
  }

  function sharesOnChange(value: string) {

    const shares = parseInt(value);
    const totalPrice = state.sharePrice * shares;

    if (shares > 0 && totalPrice <= state.balance) {
      dispatch({ type: InvestAction.SetShares, payload: shares });
    }
  }

  useEffect(() => {

    if (!metaMaskEnabled) {
      resetState();
    }

  }, [metaMaskEnabled,
    resetState]);

  useEffect(() => {
    if (!(providerDepository &&
      correctNetwork)) return;

    getSharePrice();

  }, [correctNetwork,
    providerDepository,
    getSharePrice]);

  useEffect(() => {
    if (!(metaMaskEnabled &&
      correctNetwork)) return;

    getBalance();

  }, [metaMaskEnabled,
    correctNetwork,
    providerDepositToken,
    currentWallet,
    getBalance]);

  useEffect(() => {
    if (!(metaMaskEnabled &&
      correctNetwork)) return;

    getDividends();

  }, [metaMaskEnabled,
    correctNetwork,
    providerDepository,
    currentWallet,
    getDividends]);

  useEffect(() => {
    if (!(metaMaskEnabled &&
      correctNetwork)) return;

    getAllowance();

  }, [metaMaskEnabled,
    correctNetwork,
    providerDepositToken,
    currentWallet,
    getAllowance]);

  useEffect(() => {
    if (!(correctNetwork)) return;

    getPaused();

  }, [correctNetwork,
    providerDepository,
    getPaused]);

  return (
    <Box
      minHeight='95vh'
      marginTop={5}
    >
      <Box
        component='img'
        src={WavyBackground}
        alt=''
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: 1,
          height: 1
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box
            component={Card}
            width={1}
            height={1}
            data-aos={'fade-up'}
            data-aos-delay={100}
            data-aos-offset={100}
            data-aos-duration={600}
            flexDirection={'column'}
            display={'flex'}
          >
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Typography
                variant={'h6'}
                gutterBottom
                sx={{ fontWeight: 600 }}
              >
                Claim Dividends
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography color={theme.palette.primary.light} variant={'subtitle2'} sx={{
                    textShadow: `2px 2px ${theme.palette.common.black}`
                  }}>
                    Unclaimed Dividends
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <UsdIcon />
                </Grid>
                <Grid item xs={11}>
                  <Typography color={theme.palette.common.white} variant={'subtitle2'} sx={{
                    textShadow: `2px 2px ${theme.palette.common.black}`
                  }}>
                    {state.dividends ? state.dividends.toFixed(2) : 0}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color={theme.palette.primary.light} variant={'caption'} sx={{
                    textShadow: `2px 2px ${theme.palette.common.black}`
                  }}>
                    &nbsp;
                  </Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Box
                    sx={{ marginTop: 3 }}
                    display="flex"
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    alignItems={{ xs: 'stretched', sm: 'center' }}
                    justifyContent={'space-between'}
                    width={1}
                    maxWidth={600}
                    margin={'0 auto'}
                  >
                    <Button
                      onClick={async () => { await claim(); }}
                      sx={{ width: '100px' }}
                      size={'large'}
                      variant={'contained'}
                      type={'submit'}
                      disabled={!(metaMaskEnabled && correctNetwork && state.dividends && !state.paused) || loading}
                    >
                      Claim
                    </Button >
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            component={Card}
            width={1}
            height={1}
            data-aos={'fade-up'}
            data-aos-delay={100}
            data-aos-offset={100}
            data-aos-duration={600}
            flexDirection={'column'}
            display={'flex'}
          >
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              {
                state.paused ? (
                  <Typography
                    variant={'h6'}
                    gutterBottom
                    sx={{ fontWeight: 600 }}
                  >
                    Buy {ATHAME_FINANCE_TOKEN} (Contract paused)
                  </Typography>
                ) : (
                  <Typography
                    variant={'h6'}
                    gutterBottom
                    sx={{ fontWeight: 600 }}
                  >
                    Buy {ATHAME_FINANCE_TOKEN}
                  </Typography>
                )
              }
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography color={theme.palette.primary.light} variant={'subtitle2'} sx={{
                    textShadow: `2px 2px ${theme.palette.common.black}`
                  }}>
                    Amount
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography color={theme.palette.primary.light} variant={'subtitle2'} sx={{
                        textShadow: `2px 2px ${theme.palette.common.black}`
                      }}>
                        {token.name} Balance:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {
                        state.balance ? (
                          <Typography color={theme.palette.common.white} variant={'subtitle2'} sx={{
                            textShadow: `2px 2px ${theme.palette.common.black}`
                          }}>
                            {state.balance.toFixed(2)}
                          </Typography>
                        ) : (
                          <Skeleton variant="text" />
                        )
                      }
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label=""
                    variant="standard"
                    type="number"
                    value={state.shares}
                    fullWidth
                    onChange={(e) => { sharesOnChange(e.target.value); }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="value-with-sx"
                    value={isNaN(state.shares) ||
                      state.shares < 1 ? state.sharePrice.toFixed(2) :
                      (state.sharePrice * state.shares).toFixed(2)}
                    label=""
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <UsdIcon />
                        </InputAdornment>
                      )
                    }} />
                </Grid>
                <Grid item xs={12}>
                  <Typography color={theme.palette.primary.light} variant={'caption'} sx={{
                    textShadow: `2px 2px ${theme.palette.common.black}`
                  }}>
                    *A minimum of 1 {ATHAME_FINANCE_TOKEN} required for investment
                  </Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Box
                    sx={{ marginTop: 3 }}
                    display="flex"
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    alignItems={{ xs: 'stretched', sm: 'center' }}
                    justifyContent={'space-between'}
                    width={1}
                    maxWidth={600}
                    margin={'0 auto'}
                  >
                    <Button
                      onClick={async () => { await buyShares(state.shares); }}
                      sx={{ width: '100px' }}
                      size={'large'}
                      variant={'contained'}
                      type={'submit'}
                      disabled={!(metaMaskEnabled && correctNetwork && !state.paused) || loading}
                    >
                      {msg}
                    </Button >
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Invest;

import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import { AthameContext } from '../context';

export default function WalletConnect() {
    const {
        metaMaskEnabled,
        currentWallet,
        onConnect,
        onDisconnect,
        correctNetwork,
        switchNetwork
    } = useContext(AthameContext);

    const ellipseAddress = (
        address: string,
        width: number
    ): string => {
        return `${address.slice(0, width + 2)}...${address.slice(-width)}`.toLowerCase();
    };

    return (
        <>
            <Button variant='contained'
                color='primary'
                onClick={metaMaskEnabled ? (correctNetwork ? onDisconnect : switchNetwork) : onConnect}
                size='medium'
                sx={{
                    height: '30px',
                    width: '130px'
                }}
            >
                {metaMaskEnabled ? (correctNetwork ? ellipseAddress(currentWallet.address, 5) : 'Wrong network') : 'Connect'}
            </Button>
        </>
    );
}
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import getTheme from 'theme';
import { ThemeProvider } from '@mui/material/styles';
import AOS from 'aos';
import { AthameContextProvider } from './context/';

import 'aos/dist/aos.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const App = (): JSX.Element => {

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    AOS.init({
      once: true,
      delay: 50,
      duration: 500,
      easing: 'ease-in-out',
    });
  }, []);

  useEffect(() => {
    AOS.refresh();
  });

  return (
    <ThemeProvider theme={getTheme()}>
      <CssBaseline />
      <Paper elevation={0}>
        <BrowserRouter>
          <AthameContextProvider>
            <Routes />
          </AthameContextProvider>
        </BrowserRouter>
      </Paper>
    </ThemeProvider>
  );
};

export default App;
import { ethers } from 'ethers';
import { BigNumberish } from '@ethersproject/bignumber';

export const toFloat = (value: BigNumberish, decimals: number): number => {
  return parseFloat(ethers.utils.formatUnits(value, decimals));
};

export const dollars = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  style: 'currency',
});

export const isLocalStorageAvailable = () => {
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};
import { ContextActions } from './enums/ContextActions';
import { TState, TAction } from './types';

export const initialState: TState = {
  provider: null,
  signer: null,
  providerDepository: null,
  signerDepository: null,
  providerDepositToken: null,
  signerDepositToken: null,
  providerTreasury: null,
  providerToken: null,
  metaMaskEnabled: false,
  correctNetwork: false,
  currentWallet: {
    address: '',
    balance: ''
  },
  loading: false
};

export default function contextReducer(state: TState, action: TAction): TState {
  switch (action.type) {
    case ContextActions.SetProvider: {
      return {
        ...state,
        provider: action.payload,
      };
    }
    case ContextActions.SetMetaMaskEnabled: {
      return {
        ...state,
        metaMaskEnabled: action.payload,
      };
    }
    case ContextActions.SetWalletInfo: {
      return {
        ...state,
        currentWallet: action.payload,
      };
    }
    case ContextActions.SetSigner: {
      return {
        ...state,
        signer: action.payload,
      };
    }
    case ContextActions.SetNetwork: {
      return {
        ...state,
        correctNetwork: action.payload,
      };
    }
    case ContextActions.SetDepositoryByProvider: {
      return {
        ...state,
        providerDepository: action.payload,
      };
    }
    case ContextActions.SetDepositoryBySigner: {
      return {
        ...state,
        signerDepository: action.payload,
      };
    }
    case ContextActions.SetDepositTokenByProvider: {
      return {
        ...state,
        providerDepositToken: action.payload,
      };
    }
    case ContextActions.SetDepositTokenBySigner: {
      return {
        ...state,
        signerDepositToken: action.payload,
      };
    }
    case ContextActions.SetTreasuryByProvider: {
      return {
        ...state,
        providerTreasury: action.payload,
      };
    }
    case ContextActions.SetTokenByProvider: {
      return {
        ...state,
        providerToken: action.payload,
      };
    }
    case ContextActions.SetLoading: {
      return {
        ...state,
        loading: !state.loading,
      };
    }
    case ContextActions.Reset: {
      return initialState;
    }
    default:
      return state;
  }
}



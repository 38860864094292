import React, { useContext, useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { Logo } from 'assets';
import { SingleNavItem } from '.';
import { WalletConnect } from 'components';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import { ProgressIndeterminate } from 'components';
import { AthameContext } from '../../../context';
import { getNetwork, ATHAME_FINANCE_TOKEN } from '../../../constants';
import { toFloat } from 'helpers';
import { Typography } from '@mui/material';

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSidebarOpen: () => void;
  pages: TPageItem[];
}

const Topbar = ({
  onSidebarOpen,
  pages
}: Props): JSX.Element => {
  const theme = useTheme();
  const {
    provider,
    loading,
    providerToken,
    currentWallet,
    metaMaskEnabled,
    correctNetwork,
    toggleLoading,
    onAddToken } = useContext(AthameContext);

  const [balance, setBalance] = useState(0);
  const [network, setNetwork] = useState('');

  const getBalance = useCallback(async () => {
    if (!(providerToken && currentWallet)) return;

    const decimals = await providerToken.decimals();
    const balance = await providerToken.balanceOf(currentWallet.address);
    setBalance(toFloat(balance, decimals));

  }, [providerToken, currentWallet]);

  const getChainId = useCallback(async () => {
    if (!provider) return;

    const network = await provider.getNetwork();
    const currentNetwork = getNetwork(network.chainId);

    if (currentNetwork) { setNetwork(currentNetwork.name); }

  }, [provider]);

  useEffect(() => {
    if (!(metaMaskEnabled &&
      correctNetwork)) return;

    getBalance();

  }, [metaMaskEnabled,
    correctNetwork,
    providerToken,
    currentWallet,
    toggleLoading,
    getBalance]);

  useEffect(() => {
    if (!metaMaskEnabled) return;

    getChainId();

  }, [metaMaskEnabled,
    provider,
    getChainId]);

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
        display={'flex'}
        component="a"
        href="/"
        title="Athame Finance"
        width={{ xs: 100, md: 200 }}
      >
        <Box
          component={'img'}
          src={Logo}
          height={1}
          width={1}
        />
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        {pages.map((p) => (
          <Box key={p.id}>
            <SingleNavItem
              title={p.title}
              id={p.id}
              item={p}
            />
          </Box>
        ))}
        <Tooltip title={`Add ${ATHAME_FINANCE_TOKEN} to wallet`}>
          <Link
            component='button'
            color='primary'
            variant='subtitle2'
            onClick={onAddToken}
          >
            {balance.toFixed(2)} {ATHAME_FINANCE_TOKEN}
          </Link>
        </Tooltip>
        <Typography color={theme.palette.secondary.dark} variant={'subtitle2'} sx={{
          marginLeft: 1
        }}>
          {network}
        </Typography>
        <Box marginLeft={2}>
          <WalletConnect />
        </Box>
        <Box marginLeft={3}>
          {
            loading ? (
              <Box sx={{ width: 50, height: 50 }}>
                <ProgressIndeterminate loading={true} />
              </Box>) :
              (<Box sx={{ width: 50, height: 50 }}></Box>)
          }

        </Box>
      </Box>
      <Box sx={{ display: { xs: 'flex', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default Topbar;

import { TNetwork } from './context/types';

type TToken = {
    name: string,
    symbol: string,
    decimals: number
}

export const getToken = (): TToken => {
    return {
        name: 'USD Coin',
        symbol: 'USDC',
        decimals: 6
    };
};

export const ATHAME_TOKEN_LOGO_URL = 'https://athame.finance/logo192.png';
export const ATHAME_FINANCE_TOKEN = 'ATHF';
export const CACHED_PROVIDER_KEY = 'WEB3_CONNECT_CACHED_PROVIDER';
export const DISCONNECT_EVENT = 'disconnect';
export const ACCOUNTS_CHANGED = 'accountsChanged';
export const ADD_CHAIN = 'wallet_addEthereumChain';
export const CHAIN_CHANGED = 'chainChanged';
export const WATCH_ASSET = 'wallet_watchAsset';
export const ETH_REQUEST_ACCOUNTS = 'eth_requestAccounts';
export const INSTALL_METAMASK = 'Please install MetaMask';
export const SWITCH_NETWORK = 'Please switch your network to ';
export const DISCORD_URL = 'https://discord.gg/C7FFvpry4p';
export const GITHUB_URL = 'https://github.com/athamefinance/contracts';
export const TELEGRAM_URL = 'https://t.me/athamefinance';
export const TWITTER_URL = 'https://twitter.com/athamefinance';
export const KYC_URL = 'https://www.assuredefi.io/projects/athame-finance/';
export const TOKEN_AUDIT_V1 = 'https://hazecrypto.net/audit/athametoken';
export const AUDIT_V1 = 'https://github.com/EtherAuthority/Portfolio/blob/main/Smart%20Contract%20Audits/Athame%20Finance%20Protocol%20-%20Smart%20Contracts%20Security%20Audit%20Report%20-%20REVISED.pdf';


export const getNetwork = (chainId: number): TNetwork => {
    return NETWORKS.find((item: TNetwork) => item.chainId === chainId);
};

const NETWORKS: TNetwork[] = [
    {
        name: 'Localhost',
        chainId: 31337,
        rpcUrl: 'http://127.0.0.1:8545',
        blockExplorer: 'http://127.0.0.1:8545'
    },
    {
        name: 'Ethereum Mainnet',
        chainId: 1,
        rpcUrl: 'https://api.mycryptoapi.com/eth',
        blockExplorer: 'https://etherscan.io'
    },
    {
        name: 'Mumbai',
        chainId: 80001,
        rpcUrl: 'https://rpc-mumbai.maticvigil.com',
        blockExplorer: 'https://mumbai.polygonscan.com/'
    },
    {
        name: 'Polygon',
        chainId: 137,
        rpcUrl: 'https://polygon-rpc.com/',
        blockExplorer: 'https://polygonscan.com/',
    },
    {
        name: 'Avalanche FUJI',
        chainId: 43113,
        rpcUrl: 'https://api.avax-test.network/ext/bc/C/rpc',
        blockExplorer: 'https://testnet.snowtrace.io/address/',
    },
    {
        name: 'Avalanche C-Chain',
        chainId: 43114,
        rpcUrl: 'https://api.avax.network/ext/bc/C/rpc',
        blockExplorer: 'https://snowtrace.io/address/',
    },
    {
        name: 'Harmony',
        chainId: 1666600000,
        rpcUrl: 'https://api.harmony.one',
        blockExplorer: 'https://explorer.harmony.one/'
    },
    {
        name: 'Fantom',
        chainId: 250,
        rpcUrl: 'https://rpcapi.fantom.network',
        blockExplorer: 'https://ftmscan.com/'
    },
    {
        name: 'Arbitrum',
        chainId: 42161,
        rpcUrl: 'https://arb1.arbitrum.io/rpc',
        blockExplorer: 'https://explorer.arbitrum.io/#/',
    },
    {
        name: 'Kovan',
        chainId: 42,
        rpcUrl: '',
        blockExplorer: 'https://kovan.etherscan.io/'
    },
    {
        name: 'Rinkeby',
        chainId: 4,
        rpcUrl: '',
        blockExplorer: 'https://rinkeby.etherscan.io/',
    },
    {
        name: 'Ropsten',
        chainId: 3,
        rpcUrl: '',
        blockExplorer: 'https://ropsten.etherscan.io/'
    },
    {
        name: 'Goerli',
        chainId: 5,
        rpcUrl: '',
        blockExplorer: 'https://goerli.etherscan.io/'
    }
];
import React from 'react';
export const UsdIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 500 500"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="#3574ba" d="M250,1.7C112.9,1.7,1.7,112.9,1.7,250S112.9,498.3,250,498.3S498.3,387.1,498.3,250S387.1,1.7,250,1.7z M203.5,422.7
	L203.5,422.7c-0.1,5.8-4.8,9.1-10.2,7.4C118.1,406,63.8,335.7,63.8,252.5c0-83,54.3-153.4,129.6-177.5c5.6-1.7,10.1,1.6,10.1,7.4
	v14.5c0,3.9-2.9,8.3-6.6,9.7c-59.6,21.9-102,79.1-102,145.9c0,66.9,42.5,124,102,145.7c3.7,1.4,6.6,5.8,6.6,9.7V422.7z M265.7,369
	h-0.2v-0.1c0,4.3-3.5,7.8-7.8,7.8h-15.5c-4.3,0-7.8-3.5-7.8-7.8v-24.4c-33.9-4.7-50.4-23.5-54.9-49.5c-0.8-4.5,2.7-8.3,7.2-8.3h17.7
	c3.7,0,6.8,2.7,7.6,6.2c3.3,15.3,12.2,27.2,39.4,27.2c20,0,34.3-11.3,34.3-27.9c0-16.7-8.3-23.1-37.8-27.9c-43.5-5.8-64-19-64-53
	c0-26.2,20-46.8,50.6-51v-24.1c0-4.3,3.5-7.8,7.8-7.8h15.5c4.3,0,7.8,3.5,7.8,7.8v24.6c25,4.5,40.9,18.6,46.2,42.3
	c1,4.5-2.5,8.5-7.2,8.5h-16.3c-3.5,0-6.4-2.3-7.4-5.6c-4.5-14.9-15.1-21.5-33.8-21.5c-20.6,0-31.2,9.9-31.2,23.9
	c0,14.7,6,22.1,37.6,26.6c42.7,5.8,64.8,18,64.8,54.3c0,27.5-20.6,49.9-52.6,54.9V369z M306.6,430c-5.6,1.7-10.1-1.6-10.1-7.4v-14.5
	c0-4.3,2.5-8.3,6.6-9.7c59.4-21.7,102-79,102-145.7c0-66.9-42.5-124-102-145.7c-3.7-1.4-6.6-5.8-6.6-9.7V82.8
	c0-5.8,4.7-9.1,10.1-7.4c75.3,23.7,129.6,94.1,129.6,177.1C436.2,335.7,381.9,406,306.6,430z"/>
  </svg>
);


export default UsdIcon;
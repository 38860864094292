import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Logo } from '../../../assets';
import DiscordIcon from '../../../assets/DiscordIcon';
import TelegramIcon from '@mui/icons-material/Telegram';
import IconButton from '../../IconButton';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import Divider from '@mui/material/Divider';
import {
  getNetwork,
  DISCORD_URL,
  GITHUB_URL,
  TELEGRAM_URL,
  TWITTER_URL,
  KYC_URL,
  TOKEN_AUDIT_V1,
  AUDIT_V1
} from '../../../constants';

function Copyright() {
  return (
    <React.Fragment>
      {'© '}
      Athame Finance{' '}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

const network = process.env.REACT_APP_NETWORK;
const networkSelected = getNetwork(Number(network));
const DEPOSITORY_ADDRESS = `${networkSelected.blockExplorer}/${process.env.REACT_APP_DEPOSITORY_ADDRESS}`;
const TREASURY_ADDRESS = `${networkSelected.blockExplorer}/${process.env.REACT_APP_TREASURY_ADDRESS}`;
const TOKEN_ADDRESS = `${networkSelected.blockExplorer}/${process.env.REACT_APP_TOKEN_ADDRESS}`;

const Footer = (): JSX.Element => {

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={{ xs: 'center', sm: 'flex-start' }}
          width={1}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Box
            sx={{ display: 'flex', flexDirection: 'column' }}
            component="a"
            href="/"
            title="Athame Finance"
            width={120}
          >
            <Box
              component={'img'}
              src={Logo}
              height={1}
              width={1}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography fontWeight="bold" variant="body2" color='secondary.dark'>
              Contracts
            </Typography>
            <Link
              sx={{
                '&:hover': {
                  color: 'text.secondary'
                },
              }}
              underline='none'
              target='_blank'
              component='a'
              href={DEPOSITORY_ADDRESS}
              color='text.primary'
              variant={'subtitle2'}
            >
              Depository
            </Link>
            <Link
              sx={{
                '&:hover': {
                  color: 'text.secondary'
                },
              }}
              underline='none'
              target='_blank'
              component='a'
              href={TREASURY_ADDRESS}
              color='text.primary'
              variant={'subtitle2'}
            >
              Treasury
            </Link>
            <Link
              sx={{
                '&:hover': {
                  color: 'text.secondary'
                },
              }}
              underline='none'
              target='_blank'
              component='a'
              href={TOKEN_ADDRESS}
              color='text.primary'
              variant={'subtitle2'}
            >
              Token
            </Link>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography fontWeight="bold" variant="body2" color='secondary.dark'>
              Security
            </Typography>
            <Link
              sx={{
                '&:hover': {
                  color: 'text.secondary'
                },
              }}
              underline='none'
              target='_blank'
              component='a'
              href={TOKEN_AUDIT_V1}
              color='text.primary'
              variant={'subtitle2'}
            >
              ATHF Audit
            </Link>
            <Link
              sx={{
                '&:hover': {
                  color: 'text.secondary'
                },
              }}
              underline='none'
              target='_blank'
              component='a'
              href={AUDIT_V1}
              color='text.primary'
              variant={'subtitle2'}
            >
              Depository Audit
            </Link>
            <Link
              sx={{
                '&:hover': {
                  color: 'text.secondary'
                },
              }}
              underline='none'
              target='_blank'
              component='a'
              href={AUDIT_V1}
              color='text.primary'
              variant={'subtitle2'}
            >
              Treasury Audit
            </Link>
            <Link
              sx={{
                '&:hover': {
                  color: 'text.secondary'
                },
              }}
              underline='none'
              target='_blank'
              component='a'
              href={KYC_URL}
              color='text.primary'
              variant={'subtitle2'}
            >
              Verification
            </Link>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography fontWeight="bold" variant="body2" color='secondary.dark'>
              Community
            </Typography>
            <Link
              sx={{
                '&:hover': {
                  color: 'text.secondary'
                },
              }}
              underline="none"
              component="a"
              href={TELEGRAM_URL}
              target='_blank'
              color="text.primary"
              variant={'subtitle2'}
            >
              Telegram
            </Link>
            <Link
              sx={{
                '&:hover': {
                  color: 'text.secondary'
                },
              }}
              underline="none"
              component="a"
              href={DISCORD_URL}
              target='_blank'
              color="text.primary"
              variant={'subtitle2'}
            >
              Discord
            </Link>
            <Link
              sx={{
                '&:hover': {
                  color: 'text.secondary'
                },
              }}
              underline="none"
              component="a"
              href={TWITTER_URL}
              target='_blank'
              color="text.primary"
              variant={'subtitle2'}
            >
              Twitter
            </Link>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <IconButton href={TELEGRAM_URL} target='_blank'>
          <TelegramIcon />
        </IconButton>
        <IconButton href={DISCORD_URL} target='_blank'>
          <DiscordIcon />
        </IconButton>
        <IconButton href={GITHUB_URL} target='_blank'>
          <GitHubIcon />
        </IconButton>
        <IconButton href={TWITTER_URL} target='_blank'>
          <TwitterIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={'center'}
          variant={'subtitle2'}
          color="text.secondary"
          gutterBottom
        >
          {Copyright()}
        </Typography>
        <Typography
          align={'center'}
          variant={'caption'}
          color="text.secondary"
          component={'p'}
        >
          Use of Athame Finance and the Athame Finance protocol is strictly at your own risk.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;

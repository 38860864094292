import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'components/layout';
import { Container, Curve } from 'components';
import { Invest, Dashboard } from './components';

const Landing = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Main>
      <Box
        position={'relative'}
        sx={{
          backgroundColor: theme.palette.alternate.dark,
          marginTop: -13,
          paddingTop: 13
        }}
      >
        <Container id='invest'>
          <Invest />
        </Container>
        <Curve color={theme.palette.alternate.main} />
      </Box>
      <Box sx={{
        backgroundColor: theme.palette.alternate.main
      }}>
        <Container id='dashboard'>
          <Dashboard />
        </Container>
      </Box>
    </Main>
  );
};

export default Landing;

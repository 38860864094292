import React, { useContext, useEffect, useCallback, useReducer } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PeopleIcon from '@mui/icons-material/People';
import { AthameContext } from 'context';
import { DashboardItem, EventsList } from 'components';
import { DashboardAction } from './enums';
import appReduce from './reducer';
import { TState } from './types';
import { toFloat, dollars } from 'helpers';
import { getToken } from '../../../../../constants';
import { OnInvestmentEvent, DepositEvent, ClaimEvent, TokenOwnerTransferredEvent } from 'context/contracts/AthameDepository';
import { DepositEvent as TDepositEvent, WithdrawalEvent as TWithdrawalEvent } from 'context/contracts/AthameTreasury';

const token = getToken();
const BLOCK = 1000; // subtract from current block
const initialState: TState = {
    totalAccounts: null,
    totalShareCount: null,
    sharePrice: 10,
    totalClaimed: null,
    totalUnclaimed: null,
    totalReserves: null,
    events: []
};

const Dashboard = (): JSX.Element => {
    const [state, dispatch] = useReducer(appReduce, initialState);
    const { providerDepository,
        providerTreasury,
        toggleLoading,
    } = useContext(AthameContext);

    const getDepositoryEvents = useCallback(async () => {
        if (!providerDepository) return;

        const block = await providerDepository.provider.getBlockNumber();
        const investEventFilter = providerDepository.filters.OnInvestment();
        const investEvents = await providerDepository.queryFilter(investEventFilter, block - BLOCK);

        investEvents.map((ev: OnInvestmentEvent) => {
            dispatch({
                type: DashboardAction.SetEvent, payload: {
                    transactionHash: ev.transactionHash,
                    blockNumber: ev.blockNumber,
                    eventName: ev.event,
                    contract: 'Depository'
                }
            });
        });

        const depositEventFilter = providerDepository.filters.Deposit();
        const depositEvents = await providerDepository.queryFilter(depositEventFilter, block - BLOCK);

        depositEvents.map((ev: DepositEvent) => {
            dispatch({
                type: DashboardAction.SetEvent, payload: {
                    transactionHash: ev.transactionHash,
                    blockNumber: ev.blockNumber,
                    eventName: ev.event,
                    contract: 'Depository'
                }
            });
        });

        const claimedEventFilter = providerDepository.filters.Claim();
        const claimedEvents = await providerDepository.queryFilter(claimedEventFilter, block - BLOCK);

        claimedEvents.map((ev: ClaimEvent) => {
            dispatch({
                type: DashboardAction.SetEvent, payload: {
                    transactionHash: ev.transactionHash,
                    blockNumber: ev.blockNumber,
                    eventName: ev.event,
                    contract: 'Depository'
                }
            });
        });

        const transferEventFilter = providerDepository.filters.TokenOwnerTransferred();
        const transferEvents = await providerDepository.queryFilter(transferEventFilter, block - BLOCK);

        transferEvents.map((ev: TokenOwnerTransferredEvent) => {
            dispatch({
                type: DashboardAction.SetEvent, payload: {
                    transactionHash: ev.transactionHash,
                    blockNumber: ev.blockNumber,
                    eventName: ev.event,
                    contract: 'Depository'
                }
            });
        });

    }, [providerDepository]);

    const getTreasuryEvents = useCallback(async () => {
        if (!providerTreasury) return;
        
        const block = await providerTreasury.provider.getBlockNumber();
        const depositFilter = providerTreasury.filters.Deposit();
        const depositEvents = await providerTreasury.queryFilter(depositFilter, block - BLOCK);

        depositEvents.map((ev: TDepositEvent) => {
            dispatch({
                type: DashboardAction.SetEvent, payload: {
                    transactionHash: ev.transactionHash,
                    blockNumber: ev.blockNumber,
                    eventName: ev.event,
                    contract: 'Treasury'
                }
            });
        });

        const withdrawalFilter = providerTreasury.filters.Withdrawal();
        const withdrwalEvents = await providerTreasury.queryFilter(withdrawalFilter, block - BLOCK);

        withdrwalEvents.map((ev: TWithdrawalEvent) => {
            dispatch({
                type: DashboardAction.SetEvent, payload: {
                    transactionHash: ev.transactionHash,
                    blockNumber: ev.blockNumber,
                    eventName: ev.event,
                    contract: 'Treasury'
                }
            });
        });

    }, [providerTreasury]);

    const getDepositoryStatistics = useCallback(async () => {
        if (!providerDepository) return;

        const price = toFloat(await providerDepository.sharePrice(), token.decimals);
        dispatch({ type: DashboardAction.SetSharePrice, payload: price });

        const count = await providerDepository.getAccountCount();
        dispatch({ type: DashboardAction.SetTotalAccounts, payload: Number(count) });

        const totalShareCount = await providerDepository.totalShareCount();
        dispatch({ type: DashboardAction.SetTotalShareCount, payload: Number(totalShareCount) });

        const totalClaimed = await providerDepository.totalClaimed();
        dispatch({ type: DashboardAction.SetTotalClaimed, payload: toFloat(totalClaimed, token.decimals) });

        const totalUnclaimed = await providerDepository.totalUnclaimed();
        dispatch({ type: DashboardAction.SetTotalUnclaimed, payload: toFloat(totalUnclaimed, token.decimals) });

    }, [providerDepository]);

    const getTreasuryStatistics = useCallback(async () => {
        if (!providerTreasury) return;

        const totalReserves = toFloat(await providerTreasury.totalReserves(), token.decimals);
        dispatch({ type: DashboardAction.SetTotalReserves, payload: Number(totalReserves) });

    }, [providerTreasury]);

    useEffect(() => {
              
        getDepositoryEvents();

    }, [providerDepository,
        toggleLoading,
        getDepositoryEvents]);

    useEffect(() => {

        getTreasuryEvents();

    }, [providerTreasury,
        toggleLoading,
        getTreasuryEvents]);

    useEffect(() => {

        getDepositoryStatistics();

    }, [providerDepository,
        toggleLoading,
        getDepositoryStatistics]);

    useEffect(() => {

        getTreasuryStatistics();

    }, [providerTreasury,
        toggleLoading,
        getTreasuryStatistics]);

    return (
        <Box
            minHeight='95vh'
            marginTop={5}
        >
            <Card>
                <Grid container spacing={0}>
                    <DashboardItem
                        label='Total Investors'
                        value={state.totalAccounts && state.totalAccounts.toString()}
                        icon={<PeopleIcon />} />
                    <DashboardItem
                        label='Total Tokens Minted'
                        value={state.totalShareCount && state.totalShareCount.toString()}
                        icon={<AccountBalanceWalletIcon />} />
                    <DashboardItem
                        label='Total Value'
                        value={state.totalShareCount && dollars.format(state.totalShareCount * state.sharePrice)}
                        icon={<AccountBalanceIcon />} />
                    <DashboardItem
                        label='Total Claimed'
                        value={state.totalClaimed && dollars.format(state.totalClaimed)}
                        icon={<AttachMoneyIcon />} />
                </Grid>
            </Card>
            <Box sx={{ marginTop: 4 }} data-aos={'fade-up'}>
                <Grid container spacing={{ xs: 2, md: 4 }}>
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ p: { xs: 2, md: 4 } }}>
                            <Typography color={'text.secondary'} gutterBottom>
                                Treasury Balance
                            </Typography>
                            <Typography
                                variant={'h4'}
                                color={'text.primary'}
                                fontWeight={700}
                            >
                                {
                                    state.totalReserves ?
                                        (dollars.format(state.totalReserves)) :
                                        (<Skeleton variant="text" />)
                                }
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ p: { xs: 2, md: 4 } }}>
                            <Typography color={'text.secondary'} gutterBottom>
                                Total Invested
                            </Typography>
                            <Typography
                                variant={'h4'}
                                color={'text.primary'}
                                fontWeight={700}
                            >
                                {
                                    state.totalReserves ?
                                        (dollars.format((state.totalShareCount * state.sharePrice) - state.totalReserves)) :
                                        (dollars.format((state.totalShareCount * state.sharePrice)))
                                }

                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ p: { xs: 2, md: 4 } }}>
                            <Typography color={'text.secondary'} gutterBottom>
                                Total Unclaimed
                            </Typography>
                            <Typography
                                variant={'h4'}
                                color={'text.primary'}
                                fontWeight={700}
                            >
                                {
                                    state.totalUnclaimed ?
                                        dollars.format(state.totalUnclaimed) :
                                        (<Skeleton variant="text" />)
                                }

                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Card sx={{ p: { xs: 2, md: 4 } }}>
                            <Typography color={'text.secondary'} gutterBottom>
                                Latest Events
                            </Typography>
                            <EventsList events={state.events} />
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    );
};

export default Dashboard;
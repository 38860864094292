import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import { TEvent } from './views/Landing/components/dashboard/types';
import Typography from '@mui/material/Typography';
import EventIcon from '@mui/icons-material/Event';

interface Props {
    events: TEvent[];
}

const EventsList = ({ events }: Props): JSX.Element => {
    const theme = useTheme();
    return (
        <List
            sx={{
                width: '100%',
                bgcolor: 'background.paper',
                padding: 2,
                borderRadius: 2,
                boxShadow: 2,
            }}
        >
            {events.map((item, i) => (
                <ListItem sx={{ alignItems: 'unset' }} key={i}>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                    >
                        <ListItemIcon sx={{ minWidth: 'auto' }}>
                            <Box
                                component={Avatar}
                                width={30}
                                height={30}
                                marginBottom={2}
                                bgcolor={theme.palette.primary.main}
                                color={theme.palette.background.paper}
                            >
                                <EventIcon />
                            </Box>
                        </ListItemIcon>
                        <Box
                            display={i === events.length - 1 ? 'none' : 'flex'}
                            flex={'1 1 0%'}
                            borderRight={`1px solid ${theme.palette.divider}`}
                        />
                    </Box>
                    <Box marginLeft={2}>
                        <ListItemText
                            primary={`${item.contract} ${item.eventName}`}
                            secondary={`Block: ${item.blockNumber}`}
                            primaryTypographyProps={{ fontWeight: 700 }}
                        />
                        <Box marginTop={2}>
                            <Typography variant={'subtitle2'}>
                                {`Transaction: ${item.transactionHash}`}
                            </Typography>
                        </Box>
                    </Box>
                </ListItem>
            ))}

        </List>
    );
};

export default EventsList;
export enum ContextActions {
    SetProvider = 0,
    SetSigner = 1,
    SetMetaMaskEnabled = 2,
    SetWalletInfo = 3,
    SetNetwork = 4,
    Reset = 5,
    SetDepositoryByProvider = 6,
    SetDepositoryBySigner = 7,
    SetDepositTokenByProvider = 8,
    SetDepositTokenBySigner = 9,
    SetTreasuryByProvider = 10,
    SetTokenByProvider = 11,
    SetLoading = 12
}
import React from 'react';
import { makeStyles } from '@mui/styles';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

interface Props {
    title: string;
    href: string;
    id: string;
}

export default function LinkAnimation({ title, href, id }: Props) {
    const theme = useTheme();
    const useStyles = makeStyles({
        link: {
            position: 'relative',
            color: theme.palette.text.primary,
            '&:before': {
                content: '""',
                position: 'absolute',
                width: '0',
                height: '2px',
                bottom: '-3px',
                left: '50%',
                transform: 'translate(-50%,0%)',
                backgroundColor: theme.palette.primary.light,
                transformOrigin: 'center',
                visibility: 'hidden',
                transition: 'all 0.3s ease-in-out',
            },
            '&:hover': {
                color: theme.palette.primary.main,
                transition: 'all 0.3s ease-in-out'
            },
            '&:hover:before': {
                visibility: 'visible',
                width: '100%',
            },
            '&:hover:after': {
                color: theme.palette.primary.light
            }
        },
    });
    const classes = useStyles();

    return (
        <Box
            paddingX={2}
            component={'div'}
            aria-describedby={id}
            sx={{ width: '100%', height: '100%' }}>
            <Link underline='none' className={classes.link} href={href}>
                <Typography component="span">{title}</Typography>
            </Link>
        </Box>
    );
}
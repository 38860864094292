import { InvestAction, InState } from './enums';
import { TState, TAction } from './types';

export const initialState: TState = {
    sharePrice: 10,
    shares: 10,
    balance: null,
    dividends: null,
    paused: false,
    inState: InState.NeedAllowance // state always starts here
};

export default function InvestReducer(state: TState, action: TAction): TState {
    switch (action.type) {
        case InvestAction.SetSharePrice: {
            return {
                ...state,
                sharePrice: action.payload,
            };
        }
        case InvestAction.SetDividends: {
            return {
                ...state,
                dividends: action.payload,
            };
        }
        case InvestAction.SetPaused: {
            return {
                ...state,
                paused: action.payload,
            };
        }
        case InvestAction.SetShares: {
            return {
                ...state,
                shares: action.payload,
            };
        }
        case InvestAction.SetBalance: {
            return {
                ...state,
                balance: action.payload,
            };
        }
        case InvestAction.SetInState: {
            return {
                ...state,
                inState: action.payload
            };
        }
        case InvestAction.Reset: {
            return initialState;
        }
        default:
            return state;
    }
}
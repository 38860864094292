import React, {
    createContext,
    useCallback,
    useReducer,
    useState,
    useEffect
} from 'react';
import { appReduce, initialState } from './';
import { ethers } from 'ethers';
import { ContextActions } from './enums';
import { setLocal, getLocal, removeLocal } from 'helpers';
import { InstallWalletDialog } from 'components';
import {
    ETH_REQUEST_ACCOUNTS,
    ACCOUNTS_CHANGED,
    CHAIN_CHANGED,
    CACHED_PROVIDER_KEY,
    getNetwork,
    ADD_CHAIN,
    WATCH_ASSET,
    ATHAME_FINANCE_TOKEN,
    ATHAME_TOKEN_LOGO_URL
} from '../constants';
import {
    AthameDepository__factory,
    DepositToken__factory,
    AthameTreasury__factory,
    AthameToken__factory
} from './contracts';
import { TContext } from './types';

const network = process.env.REACT_APP_NETWORK;
const RepositoryAddress = process.env.REACT_APP_DEPOSITORY_ADDRESS;
const TreasuryAddress = process.env.REACT_APP_TREASURY_ADDRESS;
const TokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;
const DepositTokenAddress = process.env.REACT_APP_DEPOSIT_TOKEN_ADDRESS;
const ProviderUrl = process.env.REACT_APP_PROVIDER_URL;

/* eslint-disable  @typescript-eslint/no-explicit-any */
declare const window: any;

interface Props {
    children: any;
}

const context: TContext = {
    provider: null,
    providerDepository: null,
    signerDepository: null,
    providerDepositToken: null,
    signerDepositToken: null,
    providerTreasury: null,
    providerToken: null,
    metaMaskEnabled: false,
    correctNetwork: false,
    currentWallet: {
        address: null,
        balance: null
    },
    onConnect: null,
    onDisconnect: null,
    toggleLoading: null,
    loading: false,
    switchNetwork: null,
    onAddToken: null
};

export const AthameContext = createContext(context);

export const AthameContextProvider = ({ children }: Props) => {
    const [state, dispatch] = useReducer(appReduce, initialState);
    const [msgState, msgSetState] = useState(false);
    const handleClose = () => {
        msgSetState(false);
    };

    const switchNetwork = async () => {
        const networkSelected = getNetwork(Number(network));
        await window.ethereum.request({
            method: ADD_CHAIN,
            params: [{
                chainId: `0x${Number(networkSelected.chainId).toString(16)}`,
                chainName: networkSelected.name,
                rpcUrls: [networkSelected.rpcUrl],
                blockExplorerUrls: ['https://snowtrace.io/'],
                nativeCurrency: {
                    name: 'Avalanche',
                    symbol: 'AVAX',
                    decimals: 18
                }
            }]
        });
    };

    const getContractByProvider = useCallback(
        async (args: ethers.providers.Provider = null) => {

            let provider = args;

            if (provider === null) {
                provider = new ethers.providers.StaticJsonRpcProvider(ProviderUrl);
            }

            const depo = AthameDepository__factory.connect(RepositoryAddress, provider);
            const depositToken = DepositToken__factory.connect(DepositTokenAddress, provider);
            const treasury = AthameTreasury__factory.connect(TreasuryAddress, provider);
            const token = AthameToken__factory.connect(TokenAddress, provider);

            dispatch(
                {
                    type: ContextActions.SetDepositoryByProvider,
                    payload: depo
                }
            );
            dispatch(
                {
                    type: ContextActions.SetDepositTokenByProvider,
                    payload: depositToken
                }
            );
            dispatch(
                {
                    type: ContextActions.SetTreasuryByProvider,
                    payload: treasury
                }
            );
            dispatch(
                {
                    type: ContextActions.SetTokenByProvider,
                    payload: token
                }
            );

        }, [dispatch]
    );

    const getContractBySigner = useCallback(
        //callback function that retrieves signer contract and puts it on our context
        async (args: ethers.Signer = null) => {

            const signer = args;
            if (!signer) return;

            const depo = AthameDepository__factory.connect(RepositoryAddress, signer);
            const depositToken = DepositToken__factory.connect(DepositTokenAddress, signer);

            dispatch(
                {
                    type: ContextActions.SetDepositoryBySigner,
                    payload: depo
                }
            );
            dispatch(
                {
                    type: ContextActions.SetDepositTokenBySigner,
                    payload: depositToken
                }
            );
        }, [dispatch]
    );

    const getCurrentWallet = useCallback(
        async (signer) => {
            if (!signer) return;

            const balance = ethers.utils.formatEther(await signer.getBalance());
            const address = await signer.getAddress();

            dispatch({ type: ContextActions.SetWalletInfo, payload: { address: address, balance: balance } });
        }, [dispatch]);

    const loadAll = useCallback(async () => {
        /* eslint-disable no-empty */
        try {

            const provider = new ethers.providers.Web3Provider(window.ethereum);
            dispatch({ type: ContextActions.SetProvider, payload: provider });
            setLocal<boolean>(CACHED_PROVIDER_KEY, true);
            await getContractByProvider();

            const signer = provider.getSigner();
            dispatch({ type: ContextActions.SetSigner, payload: signer });
            await getContractBySigner(signer);
            await getCurrentWallet(signer);

            const network = await provider.getNetwork();
            checkNetwork(network.chainId);

            dispatch({ type: ContextActions.SetMetaMaskEnabled, payload: true });
        } catch (ex) {

        }
    }, [dispatch,
        getContractByProvider,
        getContractBySigner,
        getCurrentWallet]);

    const checkNetwork = (chainId: number) => {
        let correct = true;
        // the network that the user should be on
        const networkSelected = getNetwork(Number(network));

        if (networkSelected.chainId !== chainId) {
            correct = false;
        }

        dispatch({ type: ContextActions.SetNetwork, payload: correct });
    };

    const onConnect = () => {
        const result = !!window.ethereum;

        if (!result) {
            displayPopup();
            return;
        } else {
            const requestAccess = async () => {
                /* eslint-disable no-empty */
                try {
                    // Request account access if needed
                    await window.ethereum.request({ method: ETH_REQUEST_ACCOUNTS });

                    //listener for changing network    
                    window.ethereum.on(CHAIN_CHANGED, () => loadAll());

                    //listener for changing account    
                    window.ethereum.on(ACCOUNTS_CHANGED, () => loadAll());

                    await loadAll();
                } catch (ex) {

                }
            };

            requestAccess();
        }

    };

    const onDisconnect = () => {
        dispatch({ type: ContextActions.Reset });
        removeLocal(CACHED_PROVIDER_KEY);

        const result = !!window.ethereum;

        if (result) {
            window.ethereum.removeListener(ACCOUNTS_CHANGED, () => loadAll());
            window.ethereum.removeListener(CHAIN_CHANGED, () => loadAll());
        }

    };

    const displayPopup = () => {
        msgSetState(true);
    };

    const showLoading = () => {
        dispatch(
            {
                type: ContextActions.SetLoading
            }
        );
    };

    const onAddToken = () => {
        const result = !!window.ethereum;

        if (!result) {
            displayPopup();
            return;
        } else {
            const addToken = async () => {
                /* eslint-disable no-empty */
                try {
                    await window.ethereum.request({
                        method: WATCH_ASSET,
                        params: {
                            type: 'ERC20',
                            options: {
                                address: TokenAddress, // The address that the token is at.
                                symbol: ATHAME_FINANCE_TOKEN, // A ticker symbol or shorthand, up to 5 chars.
                                decimals: 18, // The number of decimals in the token
                                image: ATHAME_TOKEN_LOGO_URL, // A string url of the token logo
                            },
                        },
                    });
                } catch (ex) {

                }
            };
            console.log('addToken');
            addToken();
        }

    };

    useEffect(() => {

        const cachedProvider = getLocal(CACHED_PROVIDER_KEY) || false;

        if (cachedProvider) {
            onConnect();
        }

    }, []);

    return (
        <AthameContext.Provider
            value={{
                provider: state.provider,
                providerDepository: state.providerDepository,
                signerDepository: state.signerDepository,
                providerDepositToken: state.providerDepositToken,
                signerDepositToken: state.signerDepositToken,
                providerTreasury: state.providerTreasury,
                providerToken: state.providerToken,
                metaMaskEnabled: state.metaMaskEnabled,
                correctNetwork: state.correctNetwork,
                currentWallet: state.currentWallet,
                onConnect: onConnect,
                onDisconnect: onDisconnect,
                toggleLoading: showLoading,
                loading: state.loading,
                switchNetwork: switchNetwork,
                onAddToken: onAddToken
            }}
        >
            <InstallWalletDialog open={msgState} onClose={handleClose} />
            {children}
        </AthameContext.Provider>
    );
};
const pages: TPageItem[] = [
  {
    title: 'Invest',
    href: '/#invest',
    id: 'invest-page'
  },
  {
    title: 'Dashboard',
    href: '/#dashboard',
    id: 'dashboard-page'
  }
];

export default pages;

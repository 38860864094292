import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';

interface Props {
  loading: boolean;
}

export default function ProgressIndeterminate({ loading }: Props) {
  const theme = useTheme();
  return (
    <>
      {loading && (
        <CircularProgress
          sx={{
            color: theme.palette.primary.light,
          }} />
      )}
    </>

  );
}
